import React from "react";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import Subverticals from "../../components/SubVerticals/Subverticals";
import { AeDemos } from "../../data/accoutexecutivedemos-data";

import ogImage from "../../images/global_assets/og-image.png";

const Legal = () => {
  return (
    <Layout className="about-us">
      <SEO
        title="SpotOn | AE Demo Schedule"
        description="SpotOn Account Executives, use the appropriate links below to request a private demo for one of your clients ."
        image={`https://spoton.com/${ogImage}`}
        noIndex
      />
      <Subverticals sectionData={AeDemos} />
    </Layout>
  );
};

export default Legal;

// eslint-disable-next-line import/prefer-default-export
export const AeDemos = {
  title:
    "Account Executives, use the appropriate links below to request a private demo for one of your clients ",
  noMore: true,
  subverticalInfo: [
    {
      iconImg: "full-service.png",
      noMore: true,
      target: "/accountexecutivedemos/restaurant",
      subverticalName: "SpotOn Restaurant",
      subverticalContent:
        "Use this link to request a private demo on SpotOn Restaurant for one of your clients.",
      links: [
        {
          linkTitle: "Schedule a demo",
          linkTarget: "/accountexecutivedemos/restaurant",
        },
      ],
    },
    {
      iconImg: "storefront.png",
      noMore: true,
      target: "https://spotonretaildemo.youcanbook.me/",
      subverticalName: "SpotOn Retail",
      subverticalContent:
        "Use this link to request a private demo on SpotOn Retail for one of your clients.",
      links: [
        {
          linkTitle: "Schedule a demo",
          linkTarget: "https://spotonretaildemo.youcanbook.me/",
        },
      ],
    },
    {
      iconImg: "reservation.png",
      noMore: true,
      target: "https://spotonreservedemo.youcanbook.me/",
      subverticalName: "SpotOn Reserve",
      subverticalContent:
        "Use this link to request a private demo on SpotOn Reserve for one of your clients.",
      links: [
        {
          linkTitle: "Schedule a demo",
          linkTarget: "https://spotonreservedemo.youcanbook.me/",
        },
      ],
    },
    {
      iconImg: "merchant.png",
      noMore: true,
      target: "https://spotondolce.youcanbook.me",
      subverticalName: "SpotOn Teamwork",
      subverticalContent:
        "Use this link to request a private demo on SpotOn Teamwork for one of your clients.",
      links: [
        {
          linkTitle: "Schedule a demo",
          linkTarget: "https://spotondolce.youcanbook.me",
        },
      ],
    },
    {
      iconImg: "loyalty.png",
      target: "https://demosatspoton.youcanbook.me/",
      subverticalName: "Marketing, Reviews, Loyalty, Appointments",
      subverticalContent:
        "Use this link to request a private demo on Spoton Marketing, Reviews, Loyalty, Appointments, & Restaurant Loyalty/Core.",
      links: [
        {
          linkTitle: "Schedule a demo",
          linkTarget: "https://demosatspoton.youcanbook.me/",
        },
      ],
    },
    {
      iconImg: "fine-dining.png",
      target: "https://demosatspoton.youcanbook.me/",
      subverticalName: "Restaurant Core & Loyalty Demo",
      subverticalContent:
        "Use this link to book a private demo for Restaurant Core/Dashboard and Loyalty.",
      links: [
        {
          linkTitle: "Schedule a demo",
          linkTarget: "https://demosatspoton.youcanbook.me/",
        },
      ],
    },
  ],
};
